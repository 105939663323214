import { Component, Inject, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AuthService, LangService, TrackingService } from '../services/service.index';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../services/window.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from '../libs/ngx-captcha';

declare function chat_zopim(active, route, nexp);
declare function showGoogleRecaptcha();

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

    public keyForm: FormGroup;
    private hash: string;
    public submitted = false;
    public errorLogin = false;
    public returnUrl: string = null;
    public body_app: any;
    private sub: any;
    public readonly siteKey = '6LfxNrspAAAAAOLEaEoggeAEaQ26onQzGnKr0Vsd';
    public langrc: string;
    public token?: string;
    public currentYear: number;
    public loading = false;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private authService: AuthService,
                private translate: TranslateService,
                private lang: LangService,
                private trackingService: TrackingService,
                private toastr: ToastrService,
                private reCaptchaV3Service: ReCaptchaV3Service) {
        this.currentYear = (new Date()).getFullYear();
        this.body_app = this._document.getElementById('body-app');
        this.route.params.subscribe(params => {
            this.hash = params['hash'];
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.lang.loadSessionLang();
        this.langrc = this.lang.getLang();
    }
    ngAfterViewInit(): void {
        this.initLoginEvent();
    }
    ngOnInit() {
        showGoogleRecaptcha();
        if (this.authService.islogged() && this.authService.getHash() === this.hash && this.returnUrl === '/') {
            this.authService.logout();
            this.router.navigate([`/app/login/goldcar/${this.hash}`]);
            this.window.location.reload();
            return false;
        }
        this.modalService.dismissAll();
        chat_zopim(true, 'login de acceso', 'no disponible en la actual ruta');
        this.body_app.classList.add('login-bg');
        this.sub = this.route
            .queryParams
            .subscribe(params => {
                if (params['error'] === 401) {
                    const errMsg: any = this.translate.instant('ErrorsMessages.Err401');
                    this.toastr.error(errMsg);
                    return false;
                }
                if (params['error'] === 500) {
                    const errMsg: any = this.translate.instant('ErrorsMessages.Err500');
                    this.toastr.error(errMsg);
                    return false;
                }
            });
        this.keyForm = this.formBuilder.group({
            accessKey: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')]]
        });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
        this.body_app.classList.remove('login-bg');
    }
    get formControls() {
        return this.keyForm.controls;
    }
    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (typeof this.formControls.accessKey.value === 'number') {
            const keystr = this.zeroMask(this.formControls.accessKey.value, 4);
            this.formControls.accessKey.setValue(keystr);
        }
        if (this.keyForm.invalid) {
            return;
        }
        this.reCaptchaV3Service.execute(this.siteKey, 'login', (token) => {
            if (token) {
                const observable = this.authService.login(this.hash, this.formControls.accessKey.value);
                if (observable == null) {
                    const errorsMessages: any = this.translate.instant('ErrorsMessages.OpenedSession');
                    this.toastr.error(errorsMessages);
                    return false;
                }
                observable
                    .pipe(first())
                    .subscribe(data => {
                            this.errorLogin = false;
                            if (this.returnUrl !== '/') {
                                this.router.navigate([this.returnUrl]);
                            } else {
                                this.router.navigate([`app/viewfine/${this.hash}`]);
                            }
                            setTimeout(() => {
                                this.loading = false;
                            }, 1000);
                        },
                        error => {
                            setTimeout(() => {
                                this.loading = false;
                            }, 1000);
                            this.errorLogin = true;
                            this.keyForm.controls['accessKey'].setValue('');
                            this.errorEvent(error.status, error.message);
                            if (error.status === 401) {
                                const errMsg: any = this.translate.instant('ErrorsMessages.Err401');
                                this.toastr.error(errMsg);
                                return false;
                            } else if (error.status === 500) {
                                const errMsg: any = this.translate.instant('ErrorsMessages.Err500');
                                this.toastr.error(errMsg);
                                return false;
                            } else {
                                const loginErrMsg: any = this.translate.instant('LoginPage.IncorrectKey');
                                this.toastr.error(loginErrMsg);
                                return false;
                            }
                        });
            }
        });
    }
    zeroMask(num, places) {
        const zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join('0') + num;
    }
    myEvent(event) {
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'statistics') {
            this.trackingService.setHash(this.hash);
            this.trackingService.actionEvent(event, 'LOGIN');
        }
    }
    initLoginEvent() {
        this.trackingService.setHash(this.hash);
        this.trackingService.initLoginEvent();
    }
    errorEvent(status, message) {
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'statistics') {
            this.trackingService.setHash(this.hash);
            this.trackingService.errorEvent(status, message);
        }
    }
}
