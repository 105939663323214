import { Component, HostListener, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../../services/window.service';
import { LangService } from '../../services/lang.service';
import {  Router, NavigationStart, NavigationEnd  } from '@angular/router';
import {  Location, PopStateEvent  } from '@angular/common';
import {  AuthService  } from '../../services/auth.service';
import {  TranslateService  } from '@ngx-translate/core';
import {  TrackingService  } from '../../services/tracking.service';
import {  ToastrService  } from 'ngx-toastr';
import {  DateUtilsService  } from '../../services/date-utils.service';
import {  ApiService  } from '../../services/api.service';
import disableScroll from 'disable-scroll';


@Component({
    selector: 'app-headerapp',
    templateUrl: './headerapp.component.html',
    styleUrls: ['./headerapp.component.css']
})
export class HeaderappComponent implements OnInit, OnDestroy {

    private readonly hash: string;
    public show = false;
    public collapse = 'closed';
    public currentLang: string;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    public finedata: any;
    public estado: string;
    public timeExpires = false;
    public data2popups: any;
    public isProcessingPay = false;
    public tipoEnlace: any;
    private checkSessionInterval: any;
    public acTiveDropdown = false;
    public hidePaymentButton = false;
    public isMobile = false;

    constructor(private lang: LangService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private router: Router,
                private authService: AuthService,
                private translate: TranslateService,
                private trackingService: TrackingService,
                private location: Location,
                private toastr: ToastrService,
                private apiService: ApiService) {
        this.hash = this.authService.getHash();
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const main_navbar = this._document.getElementById('main-navbar');
        const offset = this.window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (offset > 0) {
            main_navbar.classList.add('thicknesstop');
        } else {
            main_navbar.classList.remove('thicknesstop');
        }
    }

    toggleCollapse(device?: string) {
        if (device === 'mobile') {
            this.isMobile = true;
            this.show = !this.show;
            this.collapse = this.collapse === 'open' ? 'closed' : 'open';
            if (this.collapse === 'open') {
                document.getElementById('overlay').style.display = 'block';
                disableScroll.on();
            } else {
                document.getElementById('overlay').style.display = 'none';
                disableScroll.off();
            }
        } else {
            this.show = !this.show;
            this.isMobile = false;
        }
    }
    logout(event) {
        this.myEvent(event);
        this.authService.logout();
        this.router.navigate([`/app/login/goldcar/${ this.hash }`]);
        const infoMsg: any = this.translate.instant('InfoMessages.Info1');
        this.toastr.info(infoMsg);
    }
    ngOnInit() {
        this.currentLang = this.lang.getLang();
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (ev instanceof NavigationEnd) {
                if (ev.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
        this.getFine();
    }
    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.show = !this.show;
        localStorage.setItem('language', lang);
        this.currentLang = lang;
        if (this.isMobile) {
            this.collapse = this.collapse === 'open' ? 'closed' : 'open';
            disableScroll.off();
            document.getElementById('overlay').style.display = 'none';
        }
    }
    myEvent(event) {
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'statistics') {
            this.trackingService.setHash(this.hash);
            this.trackingService.actionEvent(event, 'LOGOUT');
        }

    }
    getFine() {
        this.apiService.getFineData(`${this.hash}`)
            .subscribe((response: any) => {
                    this.finedata = response;
                    this.estado = this.finedata.estado;
                    if (this.finedata.tipoEnlace === '1COM' || this.finedata.tipoEnlace === '2COM') {
                        if (this.finedata.fechaFinEnlace != null && this.finedata.fechaHoraServidor != null) {
                            const dateExpiration = this.finedata.fechaFinEnlace.date;
                            const datenow = this.finedata.fechaHoraServidor.date;
                            const dateExpiresf = new Date(dateExpiration.replace(' ', 'T'));
                            const datenowf = new Date(datenow.replace(' ', 'T'));
                            if (datenowf.getTime() > dateExpiresf.getTime()) {
                                this.timeExpires = true;
                            }
                        } else {
                            this.timeExpires = true;
                        }
                    }
                    this.data2popups = {
                        tipoEnlace: this.finedata.tipoEnlace,
                        expedienteGesthispania: this.finedata.expedienteGesthispania,
                        fechaProntoPago: this.finedata.fechaProntoPago,
                        fechaHoraServidor: this.finedata.fechaHoraServidor,
                        fechaFinEnlace: this.finedata.fechaFinEnlace,
                        importeAPagar: this.finedata.importeAPagar,
                        importeDenuncia: this.finedata.importeDenuncia,
                        estado: this.finedata.estado,
                        hasComision: this.finedata.hasComision,
                        hasServicioEnlaceConductor: this.finedata.hasServicioEnlaceConductor
                    };
                    if (this.finedata.tipoEnlace === '1COM_SP') {
                        this.tipoEnlace = '1COM_SP';
                    }
                    this.finedata.timezoneDescriptionPP = DateUtilsService.getTimezoneText(this.finedata.fechaProntoPago.timezone);
                    this.finedata.timezoneDescriptionFFE = DateUtilsService.getTimezoneText(this.finedata.fechaFinEnlace.timezone);
                    if (!this.isProcessingPay && this.estado === 'enviado' && !this.timeExpires) {
                        this.startCountDown();
                    }
                },
                error => {
                    this.authService.logout();
                    this.router.navigate([`/app/login/goldcar/${this.hash}`], {queryParams: {error: error.status}});
                });
    }
    startCountDown(): void {
        this.checkSessionInterval = setInterval(() => {
            const timerExpired = sessionStorage.getItem('pyf_timer_finished');
            if (timerExpired !== null) {
                if (timerExpired === '1') {
                    this.timeExpires = true;
                }
            }
            this.hidePaymentButton = !this.router.url.includes('viewfine');
        }, 1);
    }
    ngOnDestroy() {
        clearInterval(this.checkSessionInterval);
    }
    viewFine() {
        this.router.navigate([`app/viewfine/${this.hash}`]);
    }
}

